@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;
  --background: var(--white);
  --accent: var(--black);
}

.container{
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.page-scroll.active, .page-scroll:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49,130,206,var(--text-opacity));
}

.page-scroll {
    display: block;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74,85,104,var(--text-opacity));
    transition-duration: 300ms;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}

/*Carousel*/
.embla {
  position: relative;
  background-color: #ffffff;
  padding: 0;
  max-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
  height: 100%;
}

.embla__slide {
  position: relative;
  min-width: 100%;
  /* padding-left: 10px; */
}

.h-carousel{
    height: 30rem;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.embla__slide__img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

/* .embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
} */

.embla__progress {
  position: relative;
  background-color: #b1b1b1;
  max-width: 270px;
  width: calc(100% - 40px);
  height: 4px;
  overflow: hidden;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
}

.embla__progress__bar {
  position: absolute;
  background-color: #f7e332;
  width: 100%;
  top: 0;
  bottom: 0;
  left: -100%;
}

/*Scrollbar*/
/* width */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #f7e332;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #B30007;
}

/*hide spin button*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*Categories*/
.categories::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Handle */
.categories::-webkit-scrollbar-thumb {
  background: #B30007;
}

/* Handle on hover */
.categories::-webkit-scrollbar-thumb:hover {
  background: #f7e332;
}